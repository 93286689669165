import React, {useState} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import {
  Section,
  SectionTitle,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import { StaticImage } from "gatsby-plugin-image"
import ListArrow from "../images/BulletIcon.svg"
import MapStyle from "../components/MapStyle/Cities/CarportMap"
import LocationStyle from "../components/LocationStyle"

const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 855px;
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
    & strong {
      color: #000;
      font-weight: 700;
      line-height: 24px;
    }
  }
  & small {
    display: block;
    color: #0b619b;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  & ul {
    list-style: none;
    margin: 0 -5px;
    padding: 0;
    & li {
      display: inline-block;
      padding: 0 5px;
      & a {
        color: #000;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          color: #0b619b;
          text-decoration: none;
        }
      }
    }
  }
`
const LeftContent = styled.div`
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a{
      &:hover{
        text-decoration:underline;
      }
    }
  }
`

const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`        
        margin-right: -30px;
        margin-left: -30px; 
    `}
  ul {
    font-weight: 700;
    li {
      padding-left: 40px;
      vertical-align: middle;
      margin: 0;
      line-height: 26px;
      position: relative;
      + li {
        margin-top: 15px;
      }
      &:before {
        content: url(${ListArrow});
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0 0 0px 6px rgba(23, 27, 39, 0.04);
        vertical-align: middle;
        background: #fff;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }
  &.section-editor {
    + .section-editor {
      border-top: 1px solid #dde4f0;
      padding-top: 70px;
      margin-top: 70px;
    }
  }
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    & small {
      font-size: 20px;
      line-height: 38px;
      display: block;
      font-weight: 700;
      color: #999;
    }
  }
  & strong {
    display: block;
    font-weight: 700;
    color: #000;
    line-height: 28px;
  }
`

const City = ({ data, location, pageContext: { category, city } }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false);

  const pageData = data.contentfulState
  const cityPageData = city  
  const productCategory = category
  const relatedProductsData = []
    
  data.allContentfulProduct.edges.map((product) => {
     if (product.node.productCategory.name.toLowerCase() === productCategory.split("-").join(" ")) {
       relatedProductsData.push(product.node)
     } 
  })
  
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  return (
    <Layout location={location}>
      <Seo
        title={`Carports ${city} | Metal Carports ${city} ${pageData.abbreviation} | Steel Carport Kits ${city}  ${pageData.name}`}
        description={`Carports ${city}, Shop now from a massive range of Metal Carports in ${city} ${pageData.abbreviation}. Coast to Coast Carports provide best steel carport kits and Installations in ${city} ${pageData.name}.`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link
            to={`/${category}-${pageData.name
              .split(" ")
              .join("-")
              .toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
          >
            {" "}
            {pageData.name} /{" "}
          </Link>
          <span>{city}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0"
      >
        <div className="container">
          <MainPanel alignItems="center">
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal Carports in ${city}`}</h1>
                <p>
                  You can own a Metal Carport to safeguard your vehicles,
                  equipment, and tools by providing them a roof.  Coast To Coast
                  Carports, Inc. can provide you a sturdy Metal Carport in{" "}
                  {city} to keep them safely within the closest proximity of
                  your house. The galvanized steel used to make tubing and
                  panels assure you of longevity. You can choose from a variety
                  of sizes the steel carport that’ll suit your needs best!
                </p>
                <p>Metal carports in {city} are excellent structures, designed to withstand extreme weather conditions, including heavy snow, wind, and rain. We offer a wide range of styles and sizes, from one-car carports, and two-car carports to RV carports, boat carports, and more. Whether you need a place to park your vehicles or store garden equipment and other valuables, our carports are a versatile and practical solution for all your storage needs. In addition, you can even use them as a livestock shelter or outdoor gathering space. If you're planning to buy a metal carport in {city}, our building professionals are here to help. Contact us to customize your steel carport that fits your requirements.</p>
              </LeftContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                <PrimaryForm states={data.allContentfulState.edges} location = {location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle  data = {pageData} cityData={cityPageData} productCategory = {productCategory}/>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore {city}’s Metal Carports</SectionTitle>
        </div>
        <LikeProductCarousel 
        relatedProductsData={relatedProductsData} 
        openQuoteModal={() => setQuoteVisible(true)} 
        />
      </Section>
      {(pageData.abbreviation === "ID" || pageData.abbreviation === "TX" || pageData.abbreviation === "TN" || pageData.abbreviation === "OR" ) &&  
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle city={cityPageData}  />
          </div>
        </Section>
      }
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>
                Roofing Styles for Metal Carports in {city},{" "}
                {pageData.abbreviation}
              </h2>
              <p>
                Choosing the perfect roof style for your building is one of the
                first, and most important steps you’ll take during the design
                process. And here at Coast to Coast, we offer three roof styles
                for our customers to choose from. Keep reading for more
                information about each of our unique roof styles.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <ul>
                <li>
                  Regular (Good) – The regular roof is the most economical style
                  in the metal building industry. Its horizontally-oriented
                  panels make this roof best suited for areas that do not
                  experience high winds or heavy precipitation.
                </li>
                <li>
                  A-Frame (Better) – Also known as boxed-eave and A-frame
                  horizontal, this roof is a popular choice among customers.
                  With panels running from one end of the building to the other,
                  this building is best suited for areas that may experience a
                  few high winds, but not heavy rain or snowfall.
                </li>
                <li>
                  Vertical (Best) – The vertical roof is designed and engineered
                  with panels that run from side to side, easily channeling all
                  snow, rain, and other debris off the roof and away from the
                  base of the structure. We highly-recommend this roof for areas
                  with harsh weather conditions and lengths of 36’ or more.
                </li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>Types of {city} Metal Carports</h2>
              <p>
                If you’re looking for a dependable, yet affordable means of
                shelter in {city}, {pageData.abbreviation}, you’ve come to the
                right place. Our metal carports can fulfill all your storage
                needs for just a fraction of the price of a traditional, wooden
                structure. Have peace of mind knowing your vehicles, equipment,
                and other belongings will be safe and secure in a steel carport
                from Coast to Coast.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <ul>
                <li>
                  <h3>One Car Carport</h3>
                  <p>
                    If you don’t require an ample amount of space, our one car
                    carport might just be the perfect solution for you! Shelter
                    your truck or car, maybe some lawn equipment, or just a few
                    storage boxes in this standard-sized carport.
                  </p>
                </li>
                <li>
                  <h3>Two Car Carport</h3>
                  <p>
                    Slight upgrade from our one-car carport, this metal
                    structure offers twice the amount of space. This size
                    carport is excellent for customers who have multiple
                    vehicles, several pieces of equipment, or simply want to
                    declutter their home.
                  </p>
                </li>
                <li>
                  <h3>Three Car Carport</h3>
                  <p>
                    Do you have several vehicles to keep sheltered? Do you have
                    an abundance of junk in your home you wish to store
                    elsewhere? If either of these describe your situation, our
                    three-car carport can solve all your needs. You can even
                    convert this structure into the perfect home gym, office, or
                    personal library.
                  </p>
                </li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#fff"
        className="circle-right"
      >
        <div className="container mw-1120">
          <MarkdownContent>
            <h2>
              Steel Carports Installation in {city}, {pageData.abbreviation}
            </h2>
            <p>
              Despite what some people tend to say or think, the process of
              purchasing a steel carport in {city} isn’t as overwhelming as it
              may seem. Here at Coast to Coast Carports, we take the load off
              your shoulders and bring the building to you, making the delivery
              process hassle-free. We also include all delivery and installation
              services in our prices.
            </p>
            <p>
              Before we can assemble the carport or other building type on your
              property, you must have a level site that is clear of all debris
              and vegetation. If your site is not level or the installation crew
              must travel more than 50 feet to access your site, additional fees
              will be charged.
            </p>
            <h2>Metal Carports {city} Prices</h2>
            <p>
              In most cases, when the price tag of a product is not in the
              customers budget, they won’t go through with the purchase. So,
              we’ve made it a goal at Coast to Coast to make all our products
              affordable for each customer, no matter what their budget may look
              like. From the style and size of your structure, to the
              manufacturer you obtain it from, there are several factors that
              will affect the final price of your new carport. And don’t forget
              about our rent-to-own and financing programs available to
              everyone, they provide financial assistance to those who may need
              the assistance.
            </p>
            <h2>Benefits of Purchasing a {city} Metal Carport</h2>
            <p>
              When people think of a carport, they usually think of it being a
              convenient place to park their vehicle and maybe store some
              outdoor equipment. And while they are right, there are quite a few
              benefits to owning a metal carport other than convenience.
            </p>
            <ul>
              <li>Versatile</li>
              <li>Cost-Effective</li>
              <li>Quick and Easy Installation</li>
              <li>Long-Lasting</li>
              <li>Fire and Pest Resistant</li>
              <li>Fully-Customizable</li>
            </ul>
            <h2>
              Color Your Steel Carport in {city}, {pageData.abbreviation}
            </h2>
            <p>
              When choosing the ideal color combination for your metal building,
              it can be difficult when you don’t know what it will look like
              once installed. How will you know if it’s something you like? Take
              advantage of the 3D Color Planner we have available on the Coast
              to Coast Carports website. Choosing the color for the walls, trim,
              and roof of your metal carport has never been easier! Oh, and did
              we mention that you can do all this from the comfort of your home?
              This handy tool is available on our website for customers to take
              advantage of no matter where they may be!
            </p>
          </MarkdownContent>
        </div>
      </Section>
      <Section
        pt="140px"
        pb="140px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <SectionOverlayBg>
          <StaticImage src="../images/purchase-bg.jpg" alt="purchase" />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>Choose Coast to Coast for Your {city} Metal Carport</h2>
              <p>
                We have been providing customers with solutions since 2001,
                meeting every residential, agricultural, and commercial need
                they may have. When you choose to work with Coast to Coast
                Carports, you’re choosing a company that will be here for you
                every step of the way. Give us a call today at{" "}
                <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> to speak with a
                building specialist, or visit us at one of our locations in
                Stanfield, OR and Heyburn, ID.
              </p>
              <small>ALSO Explore :</small>
              <ul>
                <li>
                  <Link
                    to={`/metal-buildings-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Buildings {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-garages-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Garages {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-rv-covers-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal RV Covers {city} {pageData.abbreviation}
                  </Link>
                </li>
              </ul>
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default City

export const pageQuery = graphql`
  query MetalCarportsCityLandingQuery($id: String!, $productCategory: String!) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      locationForCities {
        cityName
        metalCarportAddress
        metalCarportContactNumber
        metalCarportLocationLink
        metalCarportMapEmbedLink {
          metalCarportMapEmbedLink
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationForCities {
            cityName
            metalCarportAddress
            metalCarportContactNumber
            metalCarportLocationLink
            metalCarportMapEmbedLink {
              metalCarportMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: { productCategory: { name: { eq: $productCategory } } }
    ) {
      edges {
        node {
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          width
          height
          length
          price
        }
      }
    }
  }
`
